.custom-classname.react-toggle--checked .react-toggle-track {
    background-color: #d8b165;
  }
  .custom-classname.react-toggle--checked .react-toggle-track:hover {
    background-color: #d8b165;
  }
  .search-box{
    padding: 2%;
    display: flex;
    border: 1px solid #EAEAEA;
    align-items: center;
    height: 40px;
  }