.clearfix:after {
    content: "";
    display: table;
    clear: both;
  }
  .clearfix{
    /* padding: 15px; */
    margin-bottom: 10px;
  }
  /* a {
    color: #5D6975;
    text-decoration: underline;
  } */
  .td-column-function-even-example{
    font-weight: bold;
  color: green;
  }
  .td-column-function-odd-example{
    font-weight: bold;
  color: red;
  }
  .td-column-function-aggregator-example{
    font-weight: bold;
    color:  rgb(236, 109, 130);
  }
  #logo {
    text-align: center;
    margin-bottom: 15px;
  }
  
  #logo img {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }
  
  .invoiceh1 {
    border-top: 2px dashed black;
    /* border-bottom: 1px solid black; */
    /* color: black;
    font-size: 1.4em;
    line-height: 1.4em;
    font-weight: normal;
    text-align: left; */
    /* margin: 0 0 20px 0; */
  }
  
  #project {
    /* float: left; */
    margin-top: 10px;
    margin-bottom: 10px;
  }
  #secProject {
  
    float: right;
    display: flex;
    margin-top: 10px;
  }
  #project2{
    /* float: right; */
    text-transform: capitalize;
    text-align: center;
    color:black;
    font-size: 12px;
    margin-left: 10px;
  }
  #project3{
    /* float: right; */
    font-size: 13px;
    margin-left: 10px;
    margin-right: 10px;
  }
  #project span {
    font-family: Arial;
    color: black;
    /* text-align: right; */
    width: 52px;
    /* margin-right: 10px; */
    margin-left: 10px;
    display: inline-block;
    font-size: 14px;
    text-align: left;
    margin-right: 30px;
  }
  
  #company {
    float: right;
    text-align: right;
  }
  
  #project div,
  #company div {
    white-space: nowrap;        
  }
  
  .invoicetable {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 20px;
  }
  
  .invoicetable tr:nth-child(2n-1) td {
    background: #F5F5F5;
  }
  
  .invoicetable th,
  .invoicetable td {
    font-size: 12px;
    color:black;
    text-align: left;
    text-overflow: unset !important;
  }
  
  .invoicetable th {
    padding: 5px 10px;
    border-bottom: 1px solid #C1CED9;
    white-space: nowrap;        
    font-weight: normal;
  }
  
  .invoicetable td {
    white-space: normal !important;
    text-align: left;
  }
  .invoicetable td ul li{
    margin-left: -15px;
  }
  
  .invoicetable td.service,
  .invoicetable td.desc {
    
    vertical-align: top;
  }
  
  .invoicetable td.unit,
  .invoicetable td.qty,
  .invoicetable td.total {
    font-size: 13px;
  }
  
  .invoicetable td.grand {
    font-size: 13px;
    /* border-top: 1px solid #5D6975;; */
  }
  #notices{
    font-size: 13px;
    margin-top: 10px;
  }
  #notices .notice {
    color: black;
    font-size: 13px;
  }
  
  .invoicefooter {
    color: black;
    width: 100%;
    height: 30px;
    bottom: 0;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 13px;
    border-top: 1px solid #C1CED9;
    padding: 8px 0;
    text-align: center;
    white-space: normal;
  }
  
  td.discount{
    color: black
  }
  .heading_text{
      font-size: 12px;
      white-space: normal;
  }
  .change_color{
    color: black;
  }